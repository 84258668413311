import "./Forms.scss";

import React, { useState } from "react";
import TextArea, { TextAreaInitState } from "./common/TextArea";
import TextField, { TextFieldInitState } from "./common/TextField";

import { SendEmail } from "helpers/BackendApi";

const ContactForm = () => {
  const [formMsg, setFormMsg] = useState("");
  const [firstName, setFirstName] = useState(TextFieldInitState);
  const [lastName, setLastName] = useState(TextFieldInitState);
  const [email, setEmail] = useState(TextFieldInitState);
  const [message, setMessage] = useState(TextAreaInitState);
  const [isWaiting, setIsWaiting] = useState(false);

  const validateForm = () => {
    let validForm = true;

    // Email
    if (email.value.length === 0) {
      setEmail({ ...email, error: true, errorMessage: "Empty email address." });
      validForm = false;
    } else if (email.value.indexOf("@") === -1 || email.value.length <= 3) {
      setEmail({
        ...email,
        error: true,
        errorMessage: "Invalid email address.",
      });
      validForm = false;
    }

    // Name fields
    if (firstName.value.length === 0) {
      setFirstName({
        ...firstName,
        error: true,
        errorMessage: "Empty first name.",
      });
      validForm = false;
    }
    if (lastName.value.length === 0) {
      setLastName({
        ...lastName,
        error: true,
        errorMessage: "Empty last name.",
      });
      validForm = false;
    }
    if (message.value.length === 0) {
      setMessage({
        ...message,
        error: true,
        errorMessage: "Please enter a message.",
      });
      validForm = false;
    }

    return validForm;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setIsWaiting(true);

      try {
        const subject = "Magellan Core Series";
        const result = await SendEmail(
          firstName.value,
          lastName.value,
          email.value,
          subject,
          message.value
        );
        if (result && result.MessageId) {
          setFirstName({ value: "" });
          setLastName({ value: "" });
          setEmail({ value: "" });
          setMessage({ value: "" });
        }
        setFormMsg("Thank you for contacting us, we will be in touch soon.");
      } catch (e) {
        setFormMsg("Something went wrong: ", e.message);
      } finally {
        setIsWaiting(false);
      }
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit} className="form-container">
      <TextField
        id="firstName"
        label="First Name"
        value={firstName.value}
        onChange={(e) => setFirstName({ value: e.target.value })}
        error={firstName.error}
        helperText={firstName.errorMessage}
        width="half"
        classes={{ root: "no-border no-border-radius" }}
      />

      <TextField
        id="lastName"
        label="Last Name"
        value={lastName.value}
        onChange={(e) => setLastName({ value: e.target.value })}
        error={lastName.error}
        helperText={lastName.errorMessage}
        width="half"
        classes={{ root: "no-border no-border-radius" }}
      />

      <TextField
        id="email"
        label="Email Address"
        value={email.value}
        onChange={(e) => setEmail({ value: e.target.value })}
        error={email.error}
        helperText={email.errorMessage}
        width="full"
        classes={{ root: "no-border no-border-radius" }}
      />

      <TextArea
        id="message-event-contact"
        label="Message"
        value={message.value}
        onChange={(e) => setMessage({ value: e.target.value })}
        error={message.error}
        helperText={message.errorMessage}
        width="full"
        classes={{ root: "no-border no-border-radius" }}
      />

      <button
        type="submit"
        disabled={isWaiting}
        className="button-brand button-secondary"
      >
        Submit
      </button>

      <h5 style={{ marginTop: "22px", marginLeft: "28px", fontSize: "16px" }}>
        {formMsg}
      </h5>
    </form>
  );
};

export default ContactForm;
