import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import SocialIcons from "./SocialIcons";

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <Grid
          container
          direction="row"
          justify="space-between"
          className="footer-inner"
        >
          <Grid item>
            <h4 className="footer-heading">
              <a
                href="https://magellangroup.com.au"
                rel="noopener noreferrer"
                className="footer-heading footer-link"
              >
                {/* Magellan <strong>InReview 2021</strong> */}
                magellangroup.com.au
              </a>
            </h4>
            {/* <div>
              <a
                href="https://magellangroup.com.au"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-text footer-link"
              >
                magellangroup.com.au
              </a>
            </div> */}
            <SocialIcons />
          </Grid>
          <Grid item>
            <h4 className="footer-text">
              &copy; Copyright 2021 |{" "}
              <a
                href="https://www.corrivium.com"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-text footer-link"
              >
                Corrivium Live
              </a>
            </h4>
            <div className="footer-text">All Rights Reserved</div>
            <a
              href="https://www.corrivium.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-text footer-link"
            >
              Privacy Policy
            </a>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
