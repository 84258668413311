import "./styles/Register.scss";

import {
  Checkbox,
  CircularProgress,
  FormGroup,
  Grid,
  MenuItem,
} from "@material-ui/core";
import React, { useState } from "react";

import { Auth } from "aws-amplify";
import Banner from "components/layout/Header";
import MuiTextfield from "@material-ui/core/TextField";
import PasswordField from "components/forms/common/Password";
import RegisterConfirmation from "./common/RegisterConfirmation";
import { SendRegistrationQuestion } from "helpers/BackendApi";
import TextArea from "components/forms/common/TextArea";
import TextField from "components/forms/common/TextField";
import parse from "html-react-parser";

const formFieldInitState = {
  value: "",
  error: false,
  errorMessage: "",
};

const Register = (props) => {
  const {title, researcher, date: dateText, passwordText} = props.config.registrationForms;
  const [user, setUser] = useState();

  const [firstName, setFirstName] = useState(formFieldInitState);
  const [lastName, setLastName] = useState(formFieldInitState);
  const [email, setEmail] = useState(formFieldInitState);
  const [emailConfirm, setEmailConfirm] = useState(formFieldInitState);
  // const [password, setPassword] = useState(formFieldInitState);
  // const [passwordConfirm, setPasswordConfirm] = useState(formFieldInitState);

  const [companyName, setCompanyName] = useState(formFieldInitState);
  const [companyLocation, setCompanyLocation] = useState(formFieldInitState);

  const [questions, setQuestions] = useState(formFieldInitState);

  const [insightsUpdates, setInsightsUpdates] = useState(false);

  const [formMsg, setFormMsg] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);

  const validateRegistrationForm = () => {
    let validForm = true;

    // Name validation
    if (firstName.value.length === 0) {
      setFirstName({
        ...firstName,
        error: true,
        errorMessage: "Empty first name.",
      });
      validForm = false;
    }
    if (lastName.value.length === 0) {
      setLastName({
        ...lastName,
        error: true,
        errorMessage: "Empty last name.",
      });
      validForm = false;
    }

    // Email validation
    if (email.value.length === 0) {
      setEmail({...email, error: true, errorMessage: "Empty email address."});
      validForm = false;
    } else if (email.value.indexOf("@") === -1 || email.value.length <= 3) {
      setEmail({
        ...email,
        error: true,
        errorMessage: "Invalid email address.",
      });
      validForm = false;
    }
    if (email.value !== emailConfirm.value) {
      setEmailConfirm({
        ...email,
        error: true,
        errorMessage: "Email addresses do not match.",
      });
      validForm = false;
    }

    // Password validation
    // if (password.value.trim().length === 0) {
    //   setPassword({
    //     ...password,
    //     error: true,
    //     errorMessage: "Please enter a password.",
    //   });
    //   validForm = false;
    // } else if (password.value.length < 8) {
    //   setPassword({
    //     ...password,
    //     error: true,
    //     errorMessage: "Your password must be at least 8 characters in length.",
    //   });
    //   validForm = false;
    // } else if (password.value !== passwordConfirm.value) {
    //   setPasswordConfirm({
    //     ...passwordConfirm,
    //     error: true,
    //     errorMessage: "Your passwords do not match.",
    //   });
    //   validForm = false;
    // }

    if (companyName.value.length === 0) {
      setCompanyName({
        ...companyName,
        error: true,
        errorMessage: "Please enter your company name.",
      });
      validForm = false;
    }

    if (companyLocation.value.length === 0) {
      setCompanyLocation({
        ...companyLocation,
        error: true,
        errorMessage: "Please select an option.",
      });
      validForm = false;
    }

    return validForm;
  };

  const handleRegistrationSubmit = async (event) => {
    event.preventDefault();

    let subs = [];
    if (insightsUpdates) subs.push("receive_updates");
    let subscribe = "";
    if (subs.length > 0) subscribe = subs.join(", ");

    if (validateRegistrationForm()) {
      setIsWaiting(true);
      setFormMsg("Registering ...");
      try {
        const registrationDetails = {
          username: email.value,
          password: 'Magellan',
          attributes: {
            email: email.value,
            given_name: firstName.value,
            family_name: lastName.value,
            locale: companyLocation.value,
            "custom:organisation": companyName.value,
            "custom:questions": questions.value,
            "custom:subscribe": subscribe,
            "custom:registration_type": "researcher/consultant",
          },
        };
        const user = await Auth.signUp(registrationDetails);
        setUser(user);
        setIsWaiting(false);

        if (questions.value !== "" && questions.value !== undefined) {
          // Send questions to email list
          SendRegistrationQuestion(
            questions.value,
            email.value,
            firstName.value,
            lastName.value
          );
        }
      } catch (e) {
        setFormMsg(`Something went wrong: ${e.message}`);
        setIsWaiting(false);
      }
    }
  };

  const renderRegistrationForm = (props) => {
    return (
      <div className="register-form-section">
        <h2>{parse(title)}</h2>
        <h3>{researcher}</h3>
        <p>{dateText}</p>
        <p className="password-text"><i>{passwordText}</i></p>

        <form
          noValidate
          autoComplete="off"
          onSubmit={handleRegistrationSubmit}
          className="register-form-container"
        >
          <TextField
            id="firstName"
            label="First Name"
            onChange={(e) => setFirstName({value: e.target.value})}
            error={firstName.error}
            helperText={firstName.errorMessage || "Enter First Name"}
            width="half"
          />

          <TextField
            id="lastName"
            label="Last Name"
            onChange={(e) => setLastName({value: e.target.value})}
            error={lastName.error}
            helperText={lastName.errorMessage || "Enter Last Name"}
            width="half"
          />

          <TextField
            id="email"
            label="Email address"
            onChange={(e) => setEmail({value: e.target.value})}
            error={email.error}
            helperText={email.errorMessage || "Enter your email"}
            width="half"
          />

          <TextField
            id="emailConfirm"
            label="Confirm email address"
            onChange={(e) => setEmailConfirm({value: e.target.value})}
            error={emailConfirm.error}
            helperText={emailConfirm.errorMessage || "Enter your email"}
            width="half"
          />

          {/* <PasswordField
            id="password"
            label="Password"
            onChange={(e) => setPassword({value: e.target.value})}
            error={password.error}
            helperText={
              password.errorMessage || "Create a password for your account"
            }
            width="half"
          /> */}

          {/* <PasswordField
            id="passwordConfirm"
            label="Confirm password"
            onChange={(e) => setPasswordConfirm({value: e.target.value})}
            error={passwordConfirm.error}
            helperText={
              passwordConfirm.errorMessage || "Enter your password again"
            }
            width="half"
          /> */}

          <TextField 
            label = "Event Code"
            width = "half"
            value = "Magellan"
            readonly
          />

          <p className="register-form-subtext">
            {/* Your password must contain uppercase letters, lowercase letters, special characters, numbers and be at least
            8 characters. */}
            Please use <b>Magellan</b> as the Event Code
          </p>

          <TextField
            id="company"
            label="Company Name"
            onChange={(e) => setCompanyName({value: e.target.value})}
            error={companyName.error}
            helperText={companyName.errorMessage || "Enter Company Name"}
            width="half"
          />

          <div className="form-input-container half">
            <MuiTextfield
              select
              id="cpdLocation"
              label="Please select your state"
              value={companyLocation.value}
              error={companyLocation.error > 0}
              helperText={companyLocation.errorMessage || "Location"}
              onChange={(e) => setCompanyLocation({value: e.target.value})}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="NSW"> New South Wales </MenuItem>
              <MenuItem value="VIC"> Victoria </MenuItem>
              <MenuItem value="QLD"> Queensland </MenuItem>
              <MenuItem value="SA"> South Australia </MenuItem>
              <MenuItem value="WA"> Western Australia </MenuItem>
              <MenuItem value="TAS"> Tasmania </MenuItem>
              <MenuItem value="NT"> Northern Territory </MenuItem>
              <MenuItem value="ACT"> Australian Capital Territory </MenuItem>
              <MenuItem value="NZL"> New Zealand </MenuItem>
            </MuiTextfield>
          </div>

          <p className="register-form-subheading">
            If you would like to pre-submit a question for our presenters to answer during the session, type in the box
            below
          </p>

          <TextArea
            id="questions"
            label="Your question here"
            onChange={(e) => setQuestions({value: e.target.value})}
            error={questions.error}
            helperText={questions.errorMessage || "Enter your question"}
            width="full"
          />

          <div className="form-input-container full">
            <FormGroup align="left">
              <label>
                <Checkbox
                  checked={insightsUpdates}
                  onChange={(e) => setInsightsUpdates(e.target.checked)}
                  name="insightsUpdates"
                  style={{marginLeft: -12}}
                />
                I would like to receive further insights and updates about Magellan
              </label>
            </FormGroup>
          </div>

          <button
            type="submit"
            disabled={isWaiting}
            className="button-brand button-secondary"
            style={{marginRight: 18}}
          >
            Submit
          </button>

          {isWaiting && (
            <Grid item>
              <CircularProgress
                size={20}
                style={{marginTop: 21, marginRight: 14}}
              />
            </Grid>
          )}
          <h5 style={{marginTop: 24}}>{formMsg}</h5>
        </form>
      </div>
    );
  };

  const renderConfirmationForm = () => {
    return (
      <RegisterConfirmation {...props.config.registrationForms.confirmation} />
    );
  };

  return (
    <>
      <Banner isAuthenticated={props.isAuthenticated} Logout={props.Logout}/>
      <div
        className={`register-section ${user ? "confirmation" : ""}`}
      >
        {user ? renderConfirmationForm() : renderRegistrationForm()}
      </div>
    </>
  );
};

export default Register;
