import React from "react";
import { Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Close from "@material-ui/icons/Close";

import "./Modal.scss";

const ResearchConsultantModal = ({ open, handleClose }) => (
  <Modal open={open} onClose={handleClose} className="modal-container">
    <div className="modal-inner">
      <button onClick={() => handleClose()} className="button-close-modal">
        <Close fontSize="small" />
      </button>

      <h3 className="modal-heading">
        Please confirm you are a financial <strong>researcher</strong> or{" "}
        <strong>asset consultant</strong> before proceeding.
      </h3>

      <div className="modal-buttons">
        <Link
          to="/register-research-consultant"
          className="button-brand button-secondary"
        >
          Yes, I am
        </Link>

        <button
          onClick={() => handleClose()}
          className="button-brand button-secondary"
        >
          No, I'm not
        </button>
      </div>

      <p>
        By clicking “<i>Yes, I Am</i>” you represent that you are a ‘wholesale
        client’ under section 761G of the Corporations Act 2001 (Cth) (the “
        <strong>Act</strong>”). Further, you represent that you will not
        directly or indirectly disseminate information contained on this website
        to a ‘retail client’ within the meaning of section 761G of the Act.
      </p>

      <p>
        This website contains general information only and does not take into
        account any person’s investment objectives, financial situation or
        needs. Nothing contained in the website constitutes a solicitation,
        recommendation, endorsement or offer to buy or sell any securities or
        other financial instruments.
      </p>
    </div>
  </Modal>
);

export default ResearchConsultantModal;
