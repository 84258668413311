const config = {
  CLIENT_ID: "MAGELLANINREVIEW",
  URL_CONFIG: "https://events-public-assets.s3-ap-southeast-2.amazonaws.com/events/magellaninreview/config/index.json?v=20210622-1",
  BITMOVIN_ANALYTICS_KEY: "ade734cd-842a-4308-b630-9d3c3b709ce0",
  MUX_ANALYTICS_KEY: "g390j6uqqi612ienbuk94q10h",
  S3_REGION: "ap-southeast-2",
  S3_BUCKET: "events-public-assets",
  COGNITO_REGION: "ap-southeast-2",
  COGNITO_USER_POOL_ID: "ap-southeast-2_50GOLPiMz",
  COGNITO_APP_CLIENT_ID: "6mi7tq3edf0bsrfn6d0gj93qt3",
  COGNITO_IDENTITY_POOL_ID: "ap-southeast-2:13b5038a-40d9-4f26-8987-735aada34d0b",
  BACKEND_REGION: "ap-southeast-2",
  BACKEND_URL: "https://x6tm1s363a.execute-api.ap-southeast-2.amazonaws.com/magellan",
  BACKEND_API_NAME: "backend-api",
  ZENDESK_API_TOKEN: "42pzgqiuUL6qTOtljOIJWcELj2lJN3qA9ADBL9Ig",
};

export default config;
