import { API } from "aws-amplify";

import Config from '../Config';

const ApiName = Config.BACKEND_API_NAME; // defined in the Amplify Configure: index.js

export const Fetch = async (verb, url, payload) => {
  let json;
  for (let retry=0; retry < 5; retry++) {
    try {
      switch (verb) {
        case 'GET': json = await API.get(ApiName, url, payload); break;
        case 'PUT': json = await API.put(ApiName, url, payload); break;
        case 'POST': json = await API.post(ApiName, url, payload); break;
        case 'DELETE': json = await API.del(ApiName, url, payload); break;
        default: throw new Error(`No REST verb. Nothing to do?`);
      }
    }
    catch (e) {
      if (e.response) {
        if(e.response.status === 502) {  // try again
          console.log(`bad gateway: retry #${retry}`, url);
          await wait(Math.random() * 1000);
          continue;
        }
        if(e.response.status === 504 ) {  // try again
          console.log(`gateway timeout: retry #${retry}`, url);
          await wait(Math.random() * 1000);
          continue;
        }
        if (e.response.status >= 400 && e.response.status < 600) { // error
          console.log(`Network error: ${e.response.status} - ${e.response.statusText}`, url);
          throw new Error(`Network error: ${e.response.status} - ${e.response.statusText}`);
        }
      }
      else {
        console.log(`General Exception: ${e}`);
        throw new Error(`${e}`);
      }
    }
    if (!json) {
      throw new Error(`Server error: no data`);
    }
    if (json.status === true) {
      return json.data;
    }
    if (json.message) {
      throw new Error(`${json.message}`);
    }
  }
  console.log(`Invalid JSON: ${json}`, url);
  throw new Error(`Server error: invalid data`);
}

const wait = async (ms) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}
