import { Fetch } from "./AmplifyApi";

export const SendEmail = async (
  firstName,
  lastName,
  email,
  subject,
  message,
  phone = false
) => {
  const phoneDetails = phone ? { phone } : {};
  const payload = {
    body: {
      firstName,
      lastName,
      email,
      subject,
      message,
      ...phoneDetails,
    },
  };
  return await Fetch("POST", "/email", payload);
};

export const SendEventQuestion = async (question, user) => {
  const payload = {
    body: {
      subject: "Submission from the Magellan event question form",
      email: user.attributes.email,
      firstName: user.attributes.given_name,
      lastName: user.attributes.family_name,
      message: question,
    },
  };
  return await Fetch("POST", "/email", payload);
};

export const SendRegistrationQuestion = async (
  question,
  email,
  firstName,
  lastName
) => {
  const payload = {
    body: {
      subject: "Question submitted from a Magellan registrant",
      message: question,
      email,
      firstName,
      lastName,
    },
  };
  return await Fetch("POST", "/email", payload);
};
