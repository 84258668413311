import React from "react";
import TextField from "@material-ui/core/TextField";

import "../Forms.scss";

export const TextAreaInitState = {
  value: "",
  error: false,
  errorMessage: "",
};

const TextFieldWrapper = ({
  width,
  id,
  label,
  value,
  onChange,
  error,
  helperText,
  rows,
  classes,
}) => {
  const rowAmount = rows ? rows : 2;
  return (
    <div className={`form-input-container ${width}`}>
      <TextField
        id={id}
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        variant="outlined"
        className="form-textarea"
        multiline={true}
        rows={rowAmount}
        fullWidth
        classes={classes}
      />
    </div>
  );
};

export default TextFieldWrapper;
