import "./styles/Register.scss";

import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
} from "@material-ui/core";
import React, { useState } from "react";

import { Auth } from "aws-amplify";
import Banner from "../components/layout/Header";
import MuiTextfield from "@material-ui/core/TextField";
import PasswordField from "components/forms/common/Password";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import RegisterConfirmation from "./common/RegisterConfirmation";
import { SendRegistrationQuestion } from "helpers/BackendApi";
import TextArea from "components/forms/common/TextArea";
import TextField from "components/forms/common/TextField";
import parse from "html-react-parser";

const formFieldInitState = {
  value: "",
  error: false,
  errorMessage: "",
};

const multipleChoiceInitState = {
  value: [],
  error: false,
  errorMessage: "",
};

const Register = (props) => {
  const {title, investor, date: dateText, passwordText} = props.config.registrationForms;
  const [user, setUser] = useState();

  const [firstName, setFirstName] = useState(formFieldInitState);
  const [lastName, setLastName] = useState(formFieldInitState);
  const [email, setEmail] = useState(formFieldInitState);
  const [emailConfirm, setEmailConfirm] = useState(formFieldInitState);
  // const [password, setPassword] = useState(formFieldInitState);
  // const [passwordConfirm, setPasswordConfirm] = useState(formFieldInitState);

  const [investorType, setInvestorType] = useState(formFieldInitState);

  const [investorTypeOther, setInvestorTypeOther] = useState(
    formFieldInitState
  );

  const [investedInMagellan, setInvestedInMagellan] = useState(
    formFieldInitState
  );

  const [investedInMagellanType, setInvestedInMagellanType] = useState(
    multipleChoiceInitState
  );

  const updateInvestedInMagellan = (e) => {
    setInvestedInMagellanType({
      value: e.target.value,
    });
  };

  const [investedInMagellanLocation, setInvestedInMagellanLocation] = useState(
    formFieldInitState
  );

  const [questions, setQuestions] = useState(formFieldInitState);

  const [insightsUpdates, setInsightsUpdates] = useState(false);

  const [formMsg, setFormMsg] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);

  const validateRegistrationForm = () => {
    let validForm = true;

    // Name validation
    if (firstName.value.length === 0) {
      setFirstName({
        ...firstName,
        error: true,
        errorMessage: "Empty first name.",
      });
      validForm = false;
    }
    if (lastName.value.length === 0) {
      setLastName({
        ...lastName,
        error: true,
        errorMessage: "Empty last name.",
      });
      validForm = false;
    }

    // Email validation
    if (email.value.length === 0) {
      setEmail({...email, error: true, errorMessage: "Empty email address."});
      validForm = false;
    } else if (email.value.indexOf("@") === -1 || email.value.length <= 3) {
      setEmail({
        ...email,
        error: true,
        errorMessage: "Invalid email address.",
      });
      validForm = false;
    }
    if (email.value !== emailConfirm.value) {
      setEmailConfirm({
        ...email,
        error: true,
        errorMessage: "Email addresses do not match.",
      });
      validForm = false;
    }

    // Password validation
    // if (password.value.trim().length === 0) {
    //   setPassword({
    //     ...password,
    //     error: true,
    //     errorMessage: "Please enter a password.",
    //   });
    //   validForm = false;
    // } else if (password.value.length < 8) {
    //   setPassword({
    //     ...password,
    //     error: true,
    //     errorMessage: "Your password must be at least 8 characters in length.",
    //   });
    //   validForm = false;
    // } else if (password.value !== passwordConfirm.value) {
    //   setPasswordConfirm({
    //     ...passwordConfirm,
    //     error: true,
    //     errorMessage: "Your passwords do not match.",
    //   });
    //   validForm = false;
    // }

    if (investorType.value.length === 0) {
      setInvestorType({
        ...investorType,
        error: true,
        errorMessage: "Please select an option.",
      });
      validForm = false;
    } else if (
      investorType.value === "other" &&
      investorTypeOther.value.length === 0
    ) {
      setInvestorTypeOther({
        ...investorTypeOther,
        error: true,
        errorMessage: "Please enter your answer.",
      });
      validForm = false;
    }

    if (investedInMagellan.value.length === 0) {
      setInvestedInMagellan({
        ...investedInMagellan,
        error: true,
        errorMessage: "Please select an option.",
      });
      validForm = false;
    } else if (
      investedInMagellan.value === "yes" &&
      investedInMagellanType.value.length === 0
    ) {
      setInvestedInMagellanType({
        ...investedInMagellanType,
        error: true,
        errorMessage: "Please select your option(s)",
      });
      validForm = false;
    }

    if (investedInMagellanLocation.value.length === 0) {
      setInvestedInMagellanLocation({
        ...investedInMagellanLocation,
        error: true,
        errorMessage: "Please select from the list",
      });
      validForm = false;
    }

    return validForm;
  };

  const handleRegistrationSubmit = async (event) => {
    event.preventDefault();

    let subs = [];
    if (insightsUpdates) subs.push("receive_updates");
    let subscribe = "";
    if (subs.length > 0) subscribe = subs.join(", ");

    if (validateRegistrationForm()) {
      setIsWaiting(true);
      setFormMsg("Registering ...");
      try {
        const registrationDetails = {
          username: email.value,
          password: 'Magellan',
          attributes: {
            email: email.value,
            given_name: firstName.value,
            family_name: lastName.value,
            locale: investedInMagellanLocation.value,
            "custom:investor_type": investorType.value,
            "custom:other": investorTypeOther.value,
            "custom:invested": investedInMagellan.value,
            "custom:funds": investedInMagellanType.value.toString(),
            "custom:questions": questions.value,
            "custom:subscribe": subscribe,
            "custom:registration_type": "investor",
          },
        };

        const user = await Auth.signUp(registrationDetails);
        setUser(user);
        setIsWaiting(false);

        if (questions.value !== "" && questions.value !== undefined) {
          // Send questions to email list
          SendRegistrationQuestion(
            questions.value,
            email.value,
            firstName.value,
            lastName.value
          );
        }
      } catch (e) {
        setFormMsg(`Something went wrong: ${e.message}`);
        setIsWaiting(false);
      }
    }
  };

  const renderRegistrationForm = (props) => {
    return (
      <div className="register-form-section">
        <h2>{parse(title)}</h2>
        <h3>{investor}</h3>
        <p>{dateText}</p>
        <p className="password-text"><i>{passwordText}</i></p>
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleRegistrationSubmit}
          className="register-form-container"
        >
          <TextField
            id="firstName"
            label="First Name"
            onChange={(e) => setFirstName({value: e.target.value})}
            error={firstName.error}
            helperText={firstName.errorMessage || "Enter First Name"}
            width="half"
          />

          <TextField
            id="lastName"
            label="Last Name"
            onChange={(e) => setLastName({value: e.target.value})}
            error={lastName.error}
            helperText={lastName.errorMessage || "Enter Last Name"}
            width="half"
          />

          <TextField
            id="email"
            label="Email address"
            onChange={(e) => setEmail({value: e.target.value})}
            error={email.error}
            helperText={email.errorMessage || "Enter your email"}
            width="half"
          />

          <TextField
            id="emailConfirm"
            label="Confirm email address"
            onChange={(e) => setEmailConfirm({value: e.target.value})}
            error={emailConfirm.error}
            helperText={emailConfirm.errorMessage || "Enter your email"}
            width="half"
          />

          {/* <PasswordField
            id="password"
            label="Password"
            onChange={(e) => setPassword({value: e.target.value})}
            error={password.error}
            helperText={
              password.errorMessage || "Create a password for your account"
            }
            width="half"
          />

          <PasswordField
            id="passwordConfirm"
            label="Confirm password"
            onChange={(e) => setPasswordConfirm({value: e.target.value})}
            error={passwordConfirm.error}
            helperText={
              passwordConfirm.errorMessage || "Enter your password again"
            }
            width="half"
          /> */}

<         TextField 
            label = "Event Code"
            width = "half"
            value = "Magellan"
            readonly
          />

          <p className="register-form-subtext">
            {/* Your password must contain uppercase letters, lowercase letters, special characters, numbers and be at least
            8 characters. */}
            Please use <b>Magellan</b> as the Event Code
          </p>

          <h4 className="register-form-subheading">
            What type of investor are you?
          </h4>

          <div className="form-input-container radio full">
            <FormGroup>
              <RadioGroup
                name="investorType"
                row
                value={investorType.value}
                onChange={(e) => {
                  setInvestorType({value: e.target.value});
                  if (e.target.value !== "other")
                    setInvestorTypeOther(formFieldInitState);
                }}
              >
                <FormControlLabel
                  label="Advised"
                  value="advised"
                  control={<Radio/>}
                />
                <FormControlLabel
                  label="Not advised"
                  value="not-advised"
                  control={<Radio/>}
                />
                <FormControlLabel
                  label="SMSF"
                  value="smsf"
                  control={<Radio/>}
                />
                <FormControlLabel
                  label="Other"
                  value="other"
                  control={<Radio/>}
                />
              </RadioGroup>
              {investorType.error && (
                <p
                  className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                  id="investorType-helper-text"
                >
                  {investorType.errorMessage}
                </p>
              )}
            </FormGroup>

            <MuiTextfield
              id="investorTypeOther"
              label="Please specify"
              value={investorTypeOther.value}
              error={investorTypeOther.error}
              helperText={
                investorTypeOther.errorMessage &&
                investorTypeOther.errorMessage !== "" &&
                investorType.value === "other"
                  ? investorTypeOther.errorMessage
                  : "Enter your answer"
              }
              onChange={(e) => setInvestorTypeOther({value: e.target.value})}
              variant="outlined"
              disabled={investorType.value !== "other"}
              className="form-radio-text-input"
            />
          </div>

          <h4 className="register-form-subheading">
            Are you invested in one of Magellan's Funds and/or a shareholder in
            Magellan Financial Group (MFG)?
          </h4>

          <div className="form-input-container full">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <RadioGroup
                    row
                    name="investedInMagellan"
                    value={investedInMagellan.value}
                    onChange={(e) => {
                      setInvestedInMagellan({value: e.target.value});
                      if (e.target.value === "no")
                        setInvestedInMagellanType(multipleChoiceInitState);
                    }}
                  >
                    <FormControlLabel label="No" value="no" control={<Radio/>}/>
                    <FormControlLabel label="Yes" value="yes" control={<Radio/>}/>
                  </RadioGroup>
                  {investedInMagellan.error && (
                    <p
                      className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                      id="investedInMagellan-helper-text"
                    >
                      {investedInMagellan.errorMessage}
                    </p>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                {investedInMagellan.value === "yes" && (
                  <MuiTextfield
                    select
                    label="Please select options below"
                    variant="outlined"
                    error={investedInMagellanType.error}
                    helperText={investedInMagellanType.errorMessage || "Please select from the drop down, you can select more than one option"}
                    fullWidth
                    SelectProps={{
                      multiple: true,
                      value: investedInMagellanType.value,
                      onChange: updateInvestedInMagellan
                    }}
                  >
                    <MenuItem value="Shareholder - Magellan Financial Group">
                      Shareholder - Magellan Financial Group
                    </MenuItem>
                    <MenuItem value="Magellan Global Fund (Hedged or Unhedged)">
                      Magellan Global Fund (Hedged or Unhedged)
                    </MenuItem>
                    <MenuItem value="Magellan Infrastructure Fund (Hedged or Unhedged)">
                      Magellan Infrastructure Fund (Hedged or Unhedged)
                    </MenuItem>
                    <MenuItem value="Magellan High Conviction Fund or Trust">
                      Magellan High Conviction Fund or Trust
                    </MenuItem>
                    <MenuItem value="Magellan Sustainable Fund">
                      Magellan Sustainable Fund
                    </MenuItem>
                    <MenuItem
                      value="MFG Core Series Fund (MFG Core International, MFG Core ESG or MFG Core Infrastructure)">
                      MFG Core Series Fund (MFG Core International, MFG Core ESG or MFG Core Infrastructure)
                    </MenuItem>
                    <MenuItem value="Airlie Australian Share Fund">
                      Airlie Australian Share Fund
                    </MenuItem>
                    <MenuItem value="Magellan FuturePay">
                      Magellan FuturePay
                    </MenuItem>
                  </MuiTextfield>
                )}
              </Grid>
            </Grid>
          </div>

          <h4 className="register-form-subheading">Location</h4>
          <div className="form-input-container full">
            <MuiTextfield
              select
              id="location"
              label="Please select your state"
              value={investedInMagellanLocation.value}
              error={investedInMagellanLocation.error}
              helperText={
                investedInMagellanLocation.errorMessage &&
                investedInMagellanLocation.errorMessage !== ""
                  ? investedInMagellanLocation.value
                  : "Please select from the list"
              }
              onChange={(e) =>
                setInvestedInMagellanLocation({value: e.target.value})
              }
              variant="outlined"
              fullWidth
            >
              <MenuItem value="NSW">New South Wales</MenuItem>
              <MenuItem value="VIC">Victoria</MenuItem>
              <MenuItem value="QLD">Queensland</MenuItem>
              <MenuItem value="SA">South Australia</MenuItem>
              <MenuItem value="WA">Western Australia</MenuItem>
              <MenuItem value="TAS">Tasmania</MenuItem>
              <MenuItem value="NT">Northern Territory</MenuItem>
              <MenuItem value="ACT">Australian Capital Territory</MenuItem>
              <MenuItem value="NZL">New Zealand</MenuItem>
            </MuiTextfield>
          </div>

          <h4 className="register-form-subheading">
            If you would like to pre-submit a question for our presenters to answer during the session, type in the box
            below
          </h4>

          <TextArea
            id="questions"
            label="Your question here"
            onChange={(e) => setQuestions({value: e.target.value})}
            error={questions.error}
            helperText={
              questions.error && questions.error !== ""
                ? questions.errorMessage
                : "Enter your question"
            }
            width="full"
          />

          <div className="form-input-container full">
            <FormGroup align="left">
              <label>
                <Checkbox
                  checked={insightsUpdates}
                  onChange={(e) => setInsightsUpdates(e.target.checked)}
                  name="insightsUpdates"
                  style={{marginLeft: -12}}
                />
                I would like to receive further insights and updates about Magellan
              </label>
            </FormGroup>
          </div>

          <button
            type="submit"
            disabled={isWaiting}
            className="button-brand button-secondary"
            style={{marginRight: 18}}
          >
            Submit
          </button>

          {isWaiting && (
            <Grid item>
              <CircularProgress
                size={20}
                style={{marginTop: 21, marginRight: 14}}
              />
            </Grid>
          )}
          <h5 style={{marginTop: 24}}>{formMsg}</h5>
        </form>
      </div>
    );
  };

  const renderConfirmationForm = () => {
    return (
      <RegisterConfirmation {...props.config.registrationForms.confirmation} />
    );
  };

  return (
    <>
      <Banner isAuthenticated={props.isAuthenticated} Logout={props.Logout}/>
      <div
        className={`register-section ${user ? "confirmation" : ""}`}
      >
        {user ? renderConfirmationForm() : renderRegistrationForm()}
      </div>
    </>
  );
};

export default Register;
