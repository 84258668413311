import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";

import Banner from "../components/layout/Header";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    minHeight: "600px",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  alert: {
    maxWidth: "600px",
    margin: "0 auto",
    backgroundColor: theme.palette.background.paper,
  },
}));

const Loading = (props) => {
  const classes = useStyles();
  return (
    <>
      <Banner isAuthenticated={props.isAuthenticated} Logout={props.Logout} />
      <div className={classes.root}>
        <Container>
          <Alert
            variant="outlined"
            severity={props.message.type}
            className={classes.alert}
          >
            <span>{props.message.description}</span>
          </Alert>
        </Container>
      </div>
    </>
  );
};

export default Loading;
