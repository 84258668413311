import "./Header.scss";

import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import magellanLogo from "assets/images/header-logo.png";
import headerLogo from "assets/images/in-review-2021-logo.png";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#FFF",
    paddingTop: 36,
    paddingBottom: 36,
    boxShadow:
      "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)",
    zIndex: 5,
    position: "relative",
  },
  gridLogo: {
    cursor: "pointer",
  },
  container1: {
    textAlign: "left",
    marginBottom: "0",
  },
  container2: {
    maxWidth: "400px",
    margin: "0 auto",
    textAlign: "left",
  },
  logo1: {
    maxWidth: '200px',
  },
  logo2: {
    width: "100%",
    height: "auto",
    margin: "0 auto",
    display: "block",
  },
  gridButtons: {
    textAlign: "right",
    alignSelf: "center",
  },
  buttonDefault: {
    marginRight: theme.spacing(2),
  },
  "@media screen and (max-width: 500px)": {
    root: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  "@media screen and (max-width: 960px)": {
    container1: {
      textAlign: "center",
      marginBottom: "10px",
    },
  },
}));

const Header = (props) => {
  const history = useHistory();
  const Home = async () => {
    history.push("/");
  };

  const classes = useStyles();

  return (
    <AppBar
      position="static"
      color="default"
      className={classes.root}
      elevation={2}
    >
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          className="header-container"
        >
          <Grid
            item
            xs={12}
            md={3}
            onClick={() => Home()}
            className={classes.gridLogo}
          >
            {/* <div className={classes.container1}>
              <img
                src={magellanLogo}
                alt="Magellan Logo"
                className={classes.logo1}
              />
            </div> */}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            onClick={() => Home()}
            className={classes.gridLogo}
          >
            <div className={classes.container2}>
              <img
                src={magellanLogo}
                alt="Magellan Logo"
                className={classes.logo2}
              />
            </div>
          </Grid>
          {props.isAuthenticated && (
            <div className="header-buttons">
              <button
                className="button-brand button-secondary"
                onClick={() => props.Logout()}
              >
                Log out
              </button>
              <a
                className="button-outline button-secondary"
                href="https://2021.magellaninreview.com.au"
                target="_blank"
                rel="noreferrer"
              >
                More Info
              </a>
            </div>
          )}
        </Grid>
      </Container>
    </AppBar>
  );
};

export default Header;
