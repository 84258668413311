import { Fetch } from "./FetchApi";

export const FetchConfig = async (url) => {
  const config = await FetchUrl(url);
  return config;
};

export const GetUrl = (url) => {
  return url + "?v=" + new Date().getTime();
};

export const GetDefaultVideo = (config) => {
  if (config && config.videos) {
    for (let i = 0; i < config.videos.length; i++) {
      if (config.defaultVideo === config.videos[i].id) {
        return config.videos[i];
      }
    }
  }
};

const FetchUrl = async (url) => {
  //console.log('Fetch Url: ', url);
  const payload = {
    method: "GET",
  };
  return await Fetch(url, payload);
};
