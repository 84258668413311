import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/routes/AppliedRoute";
import AuthenticatedRoute from "./components/routes/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/routes/UnauthenticatedRoute";

import Home from "./pages/Home";
import Event from "./pages/Event";
import Login from "./pages/Login";
import Reset from "./pages/ResetPassword";
import ActivateAccount from "./pages/ActivateAccount";
import RegisterAdvisorBroker from "./pages/RegisterAdvisorBroker";
import RegisterInvestor from "./pages/RegisterInvestor";
import RegisterOther from "./pages/RegisterOther";
import RegisterResearchConsultant from "./pages/RegisterResearchConsultant";
import NotFound from "./pages/NotFound";

export default function Routes({ appProps, config }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      <UnauthenticatedRoute
        path="/register-adviser-broker"
        exact
        component={RegisterAdvisorBroker}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/register-investor"
        exact
        component={RegisterInvestor}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/register-other"
        exact
        component={RegisterOther}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/register-research-consultant"
        exact
        component={RegisterResearchConsultant}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/activate"
        exact
        component={ActivateAccount}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={Login}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/reset"
        exact
        component={Reset}
        appProps={appProps}
      />

      <AuthenticatedRoute path="/event" component={Event} appProps={appProps} />
      <Route component={NotFound} />
    </Switch>
  );
}
