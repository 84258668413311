import "../styles/Register.scss";

import { Box, Container, Grid } from "@material-ui/core";

import React from "react";
import parse from "html-react-parser";

const RegisterConfirmation = (props) => {
  return (
    <div className="register-form-section login">
      <h2 className="secondary-heading">
        {parse(props.title)}
      </h2>
      <p className="color-dark-grey">{props.subtitle}</p>

      <Box my={6}>
        <a
          class="button-brand button-secondary"
          target="_blank"
          rel="noreferrer"
          href={props.calendarUrl}
        >
          {props.calendarButtonLabel}
        </a>
      </Box>

      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <a className="text-link" href="/">
              {props.toLandingPageLabel}
            </a>
          </Grid>
          <Grid item xs={12} sm={6}>
            <a className="text-link" href="/login">
              {props.toLoginLabel}
            </a>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default RegisterConfirmation;
