import "./Forms.scss";

import React, { useState } from "react";
import TextArea, { TextAreaInitState } from "./common/TextArea";

import { SendEventQuestion } from "helpers/BackendApi";

const QuestionForm = ({ placeholderText, user }) => {
  const [formMsg, setFormMsg] = useState("");
  const [question, setQuestion] = useState(TextAreaInitState);
  const [isWaiting, setIsWaiting] = useState(false);

  const validateForm = () => {
    let validForm = true;

    if (question.value.length === 0) {
      setQuestion({
        ...question,
        error: true,
        errorMessage: "Please enter a question.",
      });
      validForm = false;
    }
    return validForm;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setIsWaiting(true);

      try {
        const result = await SendEventQuestion(question.value, user);
        if (result && result.MessageId) {
          setQuestion({ value: "" });
        }
        setFormMsg("Thank you for your question.");
      } catch (e) {
        setFormMsg("Something went wrong: ", e.message);
      } finally {
        setIsWaiting(false);
      }
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit} className="form-container">
      <TextArea
        id="message"
        value={question.value}
        onChange={(e) => setQuestion({ value: e.target.value })}
        width="full"
        rows={3}
        error={question.errorMessage}
        helperText={
          question.errorMessage ? question.errorMessage : "Enter your question"
        }
      />

      <button
        type="submit"
        disabled={isWaiting}
        className="button-brand button-secondary"
        style={{ marginRight: 18 }}
      >
        Submit
      </button>

      <h5 style={{ marginTop: 24 }}>{formMsg}</h5>
    </form>
  );
};

export default QuestionForm;
