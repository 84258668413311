import "./Footer.scss";

import Grid from "@material-ui/core/Grid";
import LinkedInIcon from "assets/icons/linkedin.svg";
import React from "react";
import YouTubeIcon from "@material-ui/icons/YouTube";

const SocialIcons = () => {
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      spacing={1}
      className="social-icons-container"
    >
      <Grid item>
        <a
          href="https://www.youtube.com/channel/UCW1BNks78tbQyO426KNpBxQ"
          target="_blank"
          rel="noopener noreferrer"
          className="button-footer"
        >
          <YouTubeIcon className="footer-icon" />
        </a>
      </Grid>
      <Grid item>
        <a
          href="https://www.linkedin.com/company/1003321/admin"
          target="_blank"
          rel="noopener noreferrer"
          className="button-footer"
        >
          <img className="footer-icon" src={LinkedInIcon} alt="" />
        </a>
      </Grid>
    </Grid>
  );
};

export default SocialIcons;
