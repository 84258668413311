import "./styles/Event.scss";

import React, { useEffect, useMemo, useState } from "react";

import Banner from "components/layout/Header";
import EventContactForm from "components/forms/EventContactForm";
import EventQuestionForm from "components/forms/EventQuestionForm";
import { GetDefaultVideo } from "../helpers/ConfigApi";
import HelpOutline from "@material-ui/icons/HelpOutline";
import VideoPlayer from "../components/video/VideoPlayerJS";
import { v4 as uuid } from "uuid";

const Event = ({config, isAuthenticated, Logout, user}) => {
  const {playerSection, speakersSection, resourcesSection, contactSection} = config.eventPage;

  const defaultVideo = useMemo(() => GetDefaultVideo(config), [config]);

  const [playerSrc, setPlayerSrc] = useState(defaultVideo);
  const [childSrc, setChildSrc] = useState(playerSrc);

  useEffect(() => {
    setPlayerSrc(childSrc);
  }, [childSrc]);

  useEffect(() => {
    // if the player is currently playing the default video
    // reload it, if the defaultVideo has been changed,
    // or a video src has been updated in the config file
    if (playerSrc && config && config.videos) {
      for (let i = 0; i < config.videos.length; i++) {
        if (playerSrc.id === config.videos[i].id) {
          setChildSrc(defaultVideo);
        }
      }
    }
  }, [playerSrc, config, defaultVideo]);

  return (
    <>
      <Banner isAuthenticated={isAuthenticated} Logout={Logout}/>
      <div className="event-player-section">
        <div className="event-player-inner">
          <VideoPlayer src={playerSrc} userId={user.attributes.email}/>

          <div className="event-player-info-container">
            <div className="event-player-info-description">
              <h2><strong>{playerSection.title}</strong></h2>
              <h2>{playerSection.description}</h2>
              <h3>{playerSection.subtitle}</h3>
              <p>{playerSection.paragraphs}</p>
              <a href={playerSection.calendarUrl} className="button-brand button-secondary" target="_blank" rel="noreferrer">
                Add to calendar
              </a>
            </div>

            <div className="event-player-questions-container">
              <span>Q&amp;A</span>
              <h3>Submit a question</h3>
              <p>{playerSection.questionFormBlurb}</p>
              <EventQuestionForm
                user={user}
                placeholderText={playerSection.questionFormPlaceholder}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="event-content-section">
        <div className="event-content-inner">
          <div className="event-speakers-container">
            <h3 className="event-speakers-heading">{speakersSection.title}</h3>
            {speakersSection.speakers.map((speaker) => (
              <div className="event-speaker-container" key={uuid()}>
                <div className="event-speaker-header">
                  <img
                    src={speaker.portraitUrl}
                    alt={`${speaker.name} portrait`}
                  />
                  <div>
                    <h5>{speaker.title}</h5>
                    <h4>{speaker.name}</h4>
                  </div>
                </div>
                <p>{speaker.bio}</p>
              </div>
            ))}
          </div>

          <div>
            <div className="event-resources-container" style={{display: 'none'}}>
              <span>More Information</span>
              <h3>Resources and Downloads</h3>
              {resourcesSection.links.map((link) => (
                <a
                  className="event-resource-container"
                  href={link.downloadUrl}
                  key={uuid()}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>
                    <img src={link.thumbUrl} alt={`${link.title} portrait`} />
                  </div>
                  <div className="event-resource-text">
                    <h4 dangerouslySetInnerHTML={{__html: link.title}} />
                    <h5>{link.downloadText}</h5>
                    <span className="resource-divider">&nbsp;</span>
                  </div>
                </a>
              ))}
            </div>

            <div className="help-container">
              <h2>
                <HelpOutline className="help-icon"/>
                Need help?
              </h2>
              <p>
                If you are experiencing technical difficulties, please submit a
                question with support.
              </p>

              <button
                className="button-brand button-secondary"
                onClick={() => {
                  window.zE("webWidget", "open");
                }}
                style={{paddingLeft: 16, paddingRight: 16}}
              >
                Contact Support
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="event-contact-section">
        <div className="event-contact-inner flex">
          <div className="event-contact-text">
            <h2>{contactSection.title}</h2>
            <p>{contactSection.blurb}</p>
          </div>

          <EventContactForm/>
        </div>
      </div>
    </>
  );
};

export default Event;
