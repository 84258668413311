import React from "react";
import TextField from "@material-ui/core/TextField";

import "../Forms.scss";

export const TextFieldInitState = {
  value: "",
  error: false,
  errorMessage: "",
};

const TextFieldWrapper = ({
  disabled,
  width,
  id,
  label,
  value,
  onChange,
  error,
  helperText,
  classes,
}) => (
  <div className={`form-input-container ${width}`}>
    <TextField
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      variant="outlined"
      className="form-input"
      fullWidth
      disabled={disabled ? true : false}
      classes={classes}
      autoComplete="off"
    />
  </div>
);

export default TextFieldWrapper;
