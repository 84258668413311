import React, { Component } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-mux';
import installVideoAnalyticsPlugin from './InstallVideoAnalyticsPlugin';
import { VideojsAdapter } from 'bitmovin-analytics';
import { VideoSrcIsEqual } from '../../helpers/Utils';
import Config from '../../Config';

import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
//import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import UpdateIcon from '@material-ui/icons/Update';

export default class VideoPlayerJS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: props.src,
      userId: props.userId,
      isMuted: true,
      isPaused: true,
      alertVisible: true,
      alertType: 'info',
      alertMessage: 'Initialising ...',
      alertLabel: '',
      alertHandler: (e) => { },
      alertBtn: <></>
    };

    this.videoRef = React.createRef();

    this.updateVideoSrc = this.updateVideoSrc.bind(this);
    this.scrollIntoView = this.scrollIntoView.bind(this);
    this.autoplay = this.autoplay.bind(this);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.mute = this.mute.bind(this);
    this.unMute = this.unMute.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onVolume = this.onVolume.bind(this);
    this.onError = this.onError.bind(this);
    this.setAlertMessage = this.setAlertMessage.bind(this);
    this.setAlertHidden = this.setAlertHidden.bind(this);
    this.setAlertBtnState = this.setAlertBtnState.bind(this);
    this.renderAlertBtn = this.renderAlertBtn.bind(this);
    this.renderIconBtn = this.renderIconBtn.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!VideoSrcIsEqual(nextProps.src, prevState.src)) {
      return {
        src: nextProps.src
      };
    }
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!VideoSrcIsEqual(prevProps.src, this.props.src)) {
      this.setState({ src: this.props.src }, () => this.updateVideoSrc());
    }
  }

  componentDidMount() {
    const self = this;
    const { src, userId } = this.state;
    const params = {
      controls: true,
      autoplay: true,
      preload: 'auto',
      poster: src.videoPoster,
      sources: [{
        src: src.videoUrl
      }],
      liveui: true,
      fluid: true
    };
    //console.log(`Mounting Player: ${JSON.stringify(params)}`);
    this.setAlertMessage('info', 'Loading Video ...', <></>, '', () => { });
    this.videoRef.current.onerror = this.onError;
    this.player = videojs(this.videoRef.current, params, function onPlayerReady() {
      const player = this;
      const analyticsConfig = {
        key: Config.BITMOVIN_ANALYTICS_KEY,
        videoId: src.videoId,
        title: src.videoTitle,
        userId: userId,
        customData1: Config.CLIENT
      }
      // Bitmovin Analytics
      this.videojsAdapter = new VideojsAdapter(analyticsConfig, player);

      // Google Analytics
      installVideoAnalyticsPlugin(videojs);
      // player.ga({ 'debug': true });
      player.ga();

      // MUX Analytics
      player.mux({
        debug: false,
        data: {
          env_key: Config.MUX_ANALYTICS_KEY,
          sub_property_id: Config.CLIENT,
          player_name: 'main',
          player_init_time: Date.now(),
          viewer_user_id: userId,
          video_id: src.videoId,
          video_title: src.videoTitle
        }
      });

      // Button State
      this.setState({ isPaused: player.paused() });
      this.setState({ isMuted: player.muted() });

      // Events
      player.on('play', self.onPlay);
      player.on('pause', self.onPause);
      player.on('volumechange', self.onVolume);
      player.on('error', self.onError);

      setTimeout(() => self.autoplay(), 500);
    });
  }

  componentWillUnmount() {
    if (this.intId) {
      clearInterval(this.intId);
    }
    if (this.videoRef.current) {
      this.videoRef.current.onerror = undefined;
    }
    if (this.videojsAdapter) {
      this.videojsAdapter.dispose();
      this.videojsAdapter = undefined;
    }
    if (this.player) {
      this.player.dispose();
      this.player = undefined;
    }
  }

  updateVideoSrc() {
    const { src } = this.state;
    if (this.player) {
      // Update the player
      console.log(`Updating Video Source: ${src.videoUrl} ${src.videoId}`);
      this.player.poster(src.videoPoster);
      this.player.src(src.videoUrl);
      if (this.videojsAdapter) {
        // Update Bitmovin Analytics
        this.analytics.sourceChange({
          videoId: src.videoId,
          title: src.videoTitle,
        });
      }
      // Update MUX Analytics
      this.player.mux.emit('videochange', {
        video_id: src.videoId,
        video_title: src.videoTitle
      });
    }
  }

  scrollIntoView() {
    setTimeout(() => {
      this.videoNode.current.scrollIntoView({ behavior: 'smooth' })
    }, 500);
  }

  autoplay() {
    const self = this;
    console.log("autoplay");
    if (self.player.paused()) {
      this.setAlertMessage('info', 'Click <b>Play</b> button to start ...', <PlayIcon />, 'play', () => { self.player.play() });
      try {
        const promise = self.player.play();
        if (promise !== undefined) {
          promise.then(() => {
            console.log("auto play success ... ");
            this.setAlertBtnState();
          }).catch((error) => {
            console.log(error);
            console.log("playing muted ... ");
            self.player.muted(true);
            self.player.play();
          });
        }
        else {
          console.log("no play promise");
          console.log(self.player);
        }
      } catch (err) {
        console.log('err', err);
      }
    }
  }

  play() {
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  mute() {
    this.player.muted(true);
  }

  unMute() {
    this.player.muted(false);
  }

  onPlay(e) {
    console.log('paused:', this.player.paused());
    this.setState({
      isPaused: this.player.paused(),
      isMuted: this.player.muted()
    }, () => this.setAlertBtnState());
  }

  onPause(e) {
    console.log('paused:', this.player.paused());
    this.setState({
      isPaused: this.player.paused(),
      isMuted: this.player.muted()
    }, () => this.setAlertBtnState());
  }

  onVolume(e) {
    console.log('volumechanged:', this.player.volume());
    console.log('muted:', this.player.muted());
    this.setState({ isMuted: this.player.muted() }, () => this.setAlertBtnState());
  }

  onError(e) {
    const self = this;
    const message = this.player.error();
    console.log(e);
    console.log(message);
    this.setAlertMessage('error', e.message, <UpdateIcon />, 'reload', () => { self.player.play() });
  }

  setAlertMessage(type, message, icon, label, handler) {
    this.setState({
      alertVisible: true,
      alertType: type,
      alertMessage: message,
      alertBtn: icon,
      alertLabel: label,
      alertHandler: handler
    });
  }

  setAlertHidden() {
    this.setState({
      alertVisible: false
    });
  }

  setAlertBtnState() {
    const self = this;
    const isPaused = this.player.paused();
    const isMuted = this.player.muted();
    if (isPaused) {
      console.log('setAlert: isPaused');
      this.setAlertMessage('warning', 'Your player is <strong>paused</strong>.', <PlayIcon />, 'Play', () => { self.play() });
    } else if (isMuted) {
      console.log('setAlert: isMuted');
      this.setAlertMessage('warning', 'Your player is <strong>muted</strong>.', <VolumeOffIcon />, 'Unmute', () => { self.unMute() });
    }
    else {
      this.setAlertHidden();
    }
  }

  renderIconBtn(icon, label, handler) {
    if (label) {
      return (
        <Tooltip title={label}>
          <IconButton size="small" aria-label={label} onClick={handler}>
            {icon}
          </IconButton>
        </Tooltip>
      );
    }
    return <></>;
  }

  renderAlertBtn(icon, label, handler) {
    if (label) {
      return (
        <Button
          variant="contained"
          color="default"
          size="small"
          disableElevation
          onClick={handler}
          startIcon={icon}
        >
          {label}
        </Button>
      );
    }
    return <></>;
  }

  renderAlert() {
    const { alertVisible, alertType, alertMessage, alertLabel, alertHandler, alertBtn } = this.state;
    return (
      <div style={{ position: 'relative' }}>
        <Collapse in={alertVisible}>
          <Alert
            variant="outlined"
            severity={alertType}
            action={this.renderIconBtn(alertBtn, alertLabel, alertHandler)}
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.75)' }}
          >
            <span dangerouslySetInnerHTML={{ __html: alertMessage }}></span>
          </Alert>
        </Collapse>
      </div>
    );
  }
  render() {
    return (
      <div data-vjs-player style={{ backgroundColor: '#000', border: '1px solid #000' }}>
        <div style={{ position: 'absolute', top: 8, left: '25%', width: '50%', zIndex: 1 }}>
          {this.renderAlert()}
        </div>
        <video ref={this.videoRef} className='video-js vjs-default-skin vjs-big-play-centered'></video>
      </div>
    );
  }

}