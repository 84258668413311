import "./styles/Home.scss";

import { Link, useHistory } from "react-router-dom";
import React, { useLayoutEffect, useState } from "react";

import BannerBackground from "assets/images/banner-background.jpg";
import ContactForm from "components/forms/HomeContactForm";
import Header from "components/layout/Header";
import ResearchConsultantModal from "components/modal/ResearcherConsultant";
import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";

const Home = (props) => {
  const history = useHistory();
  // copy from config JSON
  const { banner, contact, registerModalRnC } = props.config.homePage;
  const [modalOpen, setModalOpen] = useState(false);

  useLayoutEffect(() => {
    if (props.isAuthenticated) {
      history.push("/event");
    }
  }, [history, props.isAuthenticated]);

  return (
    <div className={"home-container"}>
      <Header isAuthenticated={props.isAuthenticated} Logout={props.Logout} />
      <div
        className="banner-section"
        style={{ backgroundImage: `url(${BannerBackground})` }}
      >
        <Container>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={7}>
              <div className="banner-inner">
                <h1 className="primary-heading-2 color-light title">
                  {banner.title}
                </h1>
                <h2 className="primary-heading-2 color-light subtitle">
                  {banner.subtitle}
                </h2>
                <p className="text date-time color-light">
                  {banner.datetime}
                </p>
                <p className="text color-light">
                  {banner.instruction}
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="register-section" id="#register">
        <div className="section-inner ">
          <h3 className="secondary-heading color-light">
            <u>Register</u> by selecting an option below:
          </h3>

          <div className="register-links">
            <button className="button-light" onClick={() => setModalOpen(true)}>
              Researcher/Consultant
            </button>
            <ResearchConsultantModal
              open={modalOpen}
              handleClose={() => setModalOpen(false)}
              termsNconditions={registerModalRnC.blurb}
            />
            <Link to="/register-adviser-broker" className="button-light">
              Adviser/Broker
            </Link>
            <Link to="/register-investor" className="button-light">
              Investor
            </Link>
            <Link to="/register-other" className="button-light">
              Other
            </Link>
          </div>

          <h4 className="tertiary-heading color-light">
            <strong>If you have already registered please log in below</strong>
          </h4>
          <Link to="/login" className="button-brand button-secondary">
            Log in
          </Link>
        </div>
      </div>

      <div className="contact-form-section">
        <div className="section-inner flex">
          <div className="contact-form-text-container">
            <h4 className="tertiary-heading-2 color-dark-green">
              {contact.title}
            </h4>
          </div>

          <div className="contact-form-container">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
